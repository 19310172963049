.bcf--issues
  display: flex
  flex-direction: row
  flex-wrap: wrap

  > div
    flex: 0 0 250px
    margin: 10px
    padding: 10px
    &.-failed
      border: 1px solid #EAEAEA

  img
    width: 100%

.bcf--import-listing
  margin-bottom: 50px

table.attributes-table
  td,th
    padding: 5px
    text-align: center
