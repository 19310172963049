@import './form-field.children'

.op-form-field
  display: flex
  flex-direction: column

  // This is a fix for the formly-forms, which render the wrapper around a non-visible input
  &:empty
    display: none
  
  &--label,
  &--description,
  &--input,
  &--help-text
    margin-bottom: 0.5rem

  &--label-wrap
    display: flex
    flex-direction: column
    margin: 0

  &--label
    font-size: 14px
    font-weight: bold
    line-height: 1.2

    &-indicator
      color: var(--primary-color)

  &_invalid &--label
    color: var(--content-form-error-color)

  &--description,
  &--help-text
    font-size: 12px

  &--errors
    display: flex
    flex-direction: column

  &--error
    color: var(--content-form-error-color)
    margin-bottom: 1rem
    font-size: 0.8rem

  &--description,
  &--help-text,
  &--errors
    &:empty
      display: none

  // Checkbox mode
  $off: &
  &_checkbox
    #{$off}--label-wrap
      flex-direction: row
      flex-wrap: wrap

    #{$off}--input
      order: 1
      margin-right: 1rem
    #{$off}--label
      order: 2
    #{$off}--description
      order: 3

