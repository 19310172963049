.op-modal-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 9000
  background: rgba(0, 0, 0, 0.75)
  justify-content: center
  align-items: center
  display: none
  pointer-events: none

  > *
    pointer-events: all

  &_active
    display: flex

  &_not-full-screen
    background: transparent
