.upsale-notification
  max-width: 560px
  margin-bottom: 20px
  padding-bottom: 20px

  .widget-box--teaser-image
    width: 30%
    float: right
    margin-top: -20px

  .upsale--feature-reference
    padding-bottom: 2rem

.upsale-actions
  display: flex
  flex-wrap: wrap
  align-items: baseline

  .openproject--static-link
    margin-left: 1rem

.upsale--information-container
  margin-bottom: 40px

.token-form textarea
  font-family: "Lucida Console", Monaco, monospace
  max-width: 560px
