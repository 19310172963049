
$collapsible-menu-search-container-height: 35px

.collapsible-menu--container
  .collapsible-menu--search-ul
    +allow-vertical-scrolling
    -ms-overflow-style: -ms-autohiding-scrollbar
    left: 0 !important
    top: 0 !important
    width: 100% !important
    padding-top: 10px !important
    max-height: 100%
    background: none
    z-index: 0 // Prevent overlapping with project select dropdown (https://community.openproject.com/wp/28175)

    @include styled-scroll-bar


  .collapsible-menu--results-container
    padding-top: 5px
    height: calc(100% - #{$collapsible-menu-search-container-height})

    // Firefox needs more left padding for whatever reason
    html.-browser-firefox &
      padding-left: 10px

  .collapsible-menu--no-results-container
    display: block
    padding: 15px 15px 0
    display: inline-block
    width: 100%
    white-space: normal
    font-size: var(--main-menu-font-size)

  .collapsible-menu--search-container
    padding-top: 10px
    overflow: hidden
    color: var(--main-menu-font-color)

    // Specific fix for Firefox
    body.-browser-firefox &
      padding-right: 14px

    .ui-state-active
      background: none !important
      color: inherit !important

  .ui-autocomplete--category
    width: auto
    padding-left: 33px
    line-height: $menu-item-line-height
    height: $menu-item-line-height
    font-weight: normal
    text-transform: uppercase
    // border-bottom: 1px solid $main-menu-border-color
    font-size: 0.8rem
    color: var(--main-menu-fieldset-header-color)
    cursor: pointer

    &:hover
      background: var(--main-menu-bg-hover-background) !important

  // The actual search input
  .collapsible-menu--search-bar
    height: $collapsible-menu-search-container-height
    position: relative
    margin: 0 10px
    min-width: 55px

  // Category collapsible links
  .collapsible-menu--category-icon
    @include icon-font-common
    // Overridden due to main menu :/
    padding: initial !important
    font-size: 0.6rem
    line-height: $menu-item-line-height
    height: $menu-item-line-height
    margin: 0px 12px
    position: absolute
    color: var(--main-menu-fieldset-header-color)
    z-index: 1
    border: none
    background: initial

    &::before
      @include icon-mixin-arrow-up1

    &.-collapsed
      &::before
        @include icon-mixin-arrow-down1

  // Add space above the category headings
  .collapsible-menu--category-toggle
    color: var(--main-menu-fieldset-header-color) !important
    margin-top: 10px
    &:nth-child(1), &:nth-child(2)
      margin-top: 0px

  // Single result element wrapper
  .collapsible-menu--item
    display: block !important
    height: 30px !important
    border-radius: 3px

    &.-hidden
      display: none !important

    &.selected
      .collapsible-menu--item-link
        background: var(--main-menu-bg-selected-background)
        color: var(--main-menu-selected-font-color)

        &:hover
          color: var(--main-menu-font-color)

  // Link within element wrapper
  .collapsible-menu--item-link
    @include text-shortener
    color: var(--main-menu-font-color) !important
    padding: 0px 2px 0px 33px !important
    margin: 0 !important
    line-height: 30px !important
    height: 30px !important
    text-decoration: none !important

    &:hover, &:focus, &:active
      background: var(--main-menu-bg-hover-background) !important
      color: var(--main-menu-hover-font-color) !important

  // Rule complexed for specificity issues
  input[type="text"].collapsible-menu--search-input
    color: var(--main-menu-font-color)
    width: 100%
    height: 35px
    border: 1px solid rgba(231, 231, 231, 0.15)
    border-radius: 3px
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15)
    background-color: rgba(0, 0, 0, 0.15)
    padding: 0px 30px 0px 10px

    // Hide the little x to remove input where exists + supported
    &::-webkit-search-cancel-button
      display: none
    &::placeholder,
    &::-webkit-input-placeholder
      color: var(--main-menu-font-color)
      opacity: 0.5
      @include text-shortener

  .collapsible-menu--search-icon
    position: absolute
    top:   5px
    right: 10px
    color: var(--main-menu-font-color)
    opacity: 0.5

  .collapsible-menu--submenu
    margin-bottom: 10px

  .collapsible-menu--placeholder
    @include text-shortener
    color: var(--main-menu-font-color)
    font-size: var(--main-menu-font-size)
    padding: 0 10px

#sidebar
  // Cancel out the sidebar padding
  .collapsible-menu--container
    margin: 0 -15px