@mixin icon-mixin-accessibility {
    content: '\f101';
}
.icon-accessibility:before {
    content: '\f101';
}
@mixin icon-mixin-add {
    content: '\f102';
}
.icon-add:before {
    content: '\f102';
}
@mixin icon-mixin-align-center {
    content: '\f103';
}
.icon-align-center:before {
    content: '\f103';
}
@mixin icon-mixin-align-justify {
    content: '\f104';
}
.icon-align-justify:before {
    content: '\f104';
}
@mixin icon-mixin-align-left {
    content: '\f105';
}
.icon-align-left:before {
    content: '\f105';
}
@mixin icon-mixin-align-right {
    content: '\f106';
}
.icon-align-right:before {
    content: '\f106';
}
@mixin icon-mixin-arrow-down1 {
    content: '\f107';
}
.icon-arrow-down1:before {
    content: '\f107';
}
@mixin icon-mixin-arrow-down2 {
    content: '\f108';
}
.icon-arrow-down2:before {
    content: '\f108';
}
@mixin icon-mixin-arrow-left-right {
    content: '\f109';
}
.icon-arrow-left-right:before {
    content: '\f109';
}
@mixin icon-mixin-arrow-left1 {
    content: '\f10a';
}
.icon-arrow-left1:before {
    content: '\f10a';
}
@mixin icon-mixin-arrow-left2 {
    content: '\f10b';
}
.icon-arrow-left2:before {
    content: '\f10b';
}
@mixin icon-mixin-arrow-left3 {
    content: '\f10c';
}
.icon-arrow-left3:before {
    content: '\f10c';
}
@mixin icon-mixin-arrow-left4 {
    content: '\f10d';
}
.icon-arrow-left4:before {
    content: '\f10d';
}
@mixin icon-mixin-arrow-right2 {
    content: '\f10e';
}
.icon-arrow-right2:before {
    content: '\f10e';
}
@mixin icon-mixin-arrow-right3 {
    content: '\f10f';
}
.icon-arrow-right3:before {
    content: '\f10f';
}
@mixin icon-mixin-arrow-right4 {
    content: '\f110';
}
.icon-arrow-right4:before {
    content: '\f110';
}
@mixin icon-mixin-arrow-right5 {
    content: '\f111';
}
.icon-arrow-right5:before {
    content: '\f111';
}
@mixin icon-mixin-arrow-right6 {
    content: '\f112';
}
.icon-arrow-right6:before {
    content: '\f112';
}
@mixin icon-mixin-arrow-right7 {
    content: '\f113';
}
.icon-arrow-right7:before {
    content: '\f113';
}
@mixin icon-mixin-arrow-thin {
    content: '\f114';
}
.icon-arrow-thin:before {
    content: '\f114';
}
@mixin icon-mixin-arrow-up1 {
    content: '\f115';
}
.icon-arrow-up1:before {
    content: '\f115';
}
@mixin icon-mixin-arrow-up2 {
    content: '\f116';
}
.icon-arrow-up2:before {
    content: '\f116';
}
@mixin icon-mixin-assigned-to-me {
    content: '\f117';
}
.icon-assigned-to-me:before {
    content: '\f117';
}
@mixin icon-mixin-attachment {
    content: '\f118';
}
.icon-attachment:before {
    content: '\f118';
}
@mixin icon-mixin-attention {
    content: '\f119';
}
.icon-attention:before {
    content: '\f119';
}
@mixin icon-mixin-back-up {
    content: '\f11a';
}
.icon-back-up:before {
    content: '\f11a';
}
@mixin icon-mixin-backlogs {
    content: '\f11b';
}
.icon-backlogs:before {
    content: '\f11b';
}
@mixin icon-mixin-bcf {
    content: '\f11c';
}
.icon-bcf:before {
    content: '\f11c';
}
@mixin icon-mixin-billing-information {
    content: '\f11d';
}
.icon-billing-information:before {
    content: '\f11d';
}
@mixin icon-mixin-boards {
    content: '\f11e';
}
.icon-boards:before {
    content: '\f11e';
}
@mixin icon-mixin-bold {
    content: '\f11f';
}
.icon-bold:before {
    content: '\f11f';
}
@mixin icon-mixin-budget {
    content: '\f120';
}
.icon-budget:before {
    content: '\f120';
}
@mixin icon-mixin-bug {
    content: '\f121';
}
.icon-bug:before {
    content: '\f121';
}
@mixin icon-mixin-calendar {
    content: '\f122';
}
.icon-calendar:before {
    content: '\f122';
}
@mixin icon-mixin-calendar2 {
    content: '\f123';
}
.icon-calendar2:before {
    content: '\f123';
}
@mixin icon-mixin-camera {
    content: '\f124';
}
.icon-camera:before {
    content: '\f124';
}
@mixin icon-mixin-cancel {
    content: '\f125';
}
.icon-cancel:before {
    content: '\f125';
}
@mixin icon-mixin-cart {
    content: '\f126';
}
.icon-cart:before {
    content: '\f126';
}
@mixin icon-mixin-changeset-down {
    content: '\f127';
}
.icon-changeset-down:before {
    content: '\f127';
}
@mixin icon-mixin-changeset-up {
    content: '\f128';
}
.icon-changeset-up:before {
    content: '\f128';
}
@mixin icon-mixin-changeset {
    content: '\f129';
}
.icon-changeset:before {
    content: '\f129';
}
@mixin icon-mixin-chart1 {
    content: '\f12a';
}
.icon-chart1:before {
    content: '\f12a';
}
@mixin icon-mixin-chart2 {
    content: '\f12b';
}
.icon-chart2:before {
    content: '\f12b';
}
@mixin icon-mixin-chart3 {
    content: '\f12c';
}
.icon-chart3:before {
    content: '\f12c';
}
@mixin icon-mixin-checkmark {
    content: '\f12d';
}
.icon-checkmark:before {
    content: '\f12d';
}
@mixin icon-mixin-close {
    content: '\f12e';
}
.icon-close:before {
    content: '\f12e';
}
@mixin icon-mixin-code-tag {
    content: '\f12f';
}
.icon-code-tag:before {
    content: '\f12f';
}
@mixin icon-mixin-color-text {
    content: '\f130';
}
.icon-color-text:before {
    content: '\f130';
}
@mixin icon-mixin-color-underline {
    content: '\f131';
}
.icon-color-underline:before {
    content: '\f131';
}
@mixin icon-mixin-column-left {
    content: '\f132';
}
.icon-column-left:before {
    content: '\f132';
}
@mixin icon-mixin-column-right {
    content: '\f133';
}
.icon-column-right:before {
    content: '\f133';
}
@mixin icon-mixin-columns {
    content: '\f134';
}
.icon-columns:before {
    content: '\f134';
}
@mixin icon-mixin-compare2 {
    content: '\f135';
}
.icon-compare2:before {
    content: '\f135';
}
@mixin icon-mixin-concept {
    content: '\f136';
}
.icon-concept:before {
    content: '\f136';
}
@mixin icon-mixin-console-light {
    content: '\f137';
}
.icon-console-light:before {
    content: '\f137';
}
@mixin icon-mixin-console {
    content: '\f138';
}
.icon-console:before {
    content: '\f138';
}
@mixin icon-mixin-contacts {
    content: '\f139';
}
.icon-contacts:before {
    content: '\f139';
}
@mixin icon-mixin-copy {
    content: '\f13a';
}
.icon-copy:before {
    content: '\f13a';
}
@mixin icon-mixin-cost-reports {
    content: '\f13b';
}
.icon-cost-reports:before {
    content: '\f13b';
}
@mixin icon-mixin-cost-types {
    content: '\f13c';
}
.icon-cost-types:before {
    content: '\f13c';
}
@mixin icon-mixin-cursor {
    content: '\f13d';
}
.icon-cursor:before {
    content: '\f13d';
}
@mixin icon-mixin-custom-development {
    content: '\f13e';
}
.icon-custom-development:before {
    content: '\f13e';
}
@mixin icon-mixin-custom-fields {
    content: '\f13f';
}
.icon-custom-fields:before {
    content: '\f13f';
}
@mixin icon-mixin-cut {
    content: '\f140';
}
.icon-cut:before {
    content: '\f140';
}
@mixin icon-mixin-delete-folder {
    content: '\f141';
}
.icon-delete-folder:before {
    content: '\f141';
}
@mixin icon-mixin-delete {
    content: '\f142';
}
.icon-delete:before {
    content: '\f142';
}
@mixin icon-mixin-dependency {
    content: '\f143';
}
.icon-dependency:before {
    content: '\f143';
}
@mixin icon-mixin-design {
    content: '\f144';
}
.icon-design:before {
    content: '\f144';
}
@mixin icon-mixin-double-arrow-left {
    content: '\f145';
}
.icon-double-arrow-left:before {
    content: '\f145';
}
@mixin icon-mixin-double-arrow-right {
    content: '\f146';
}
.icon-double-arrow-right:before {
    content: '\f146';
}
@mixin icon-mixin-download {
    content: '\f147';
}
.icon-download:before {
    content: '\f147';
}
@mixin icon-mixin-drag-handle {
    content: '\f148';
}
.icon-drag-handle:before {
    content: '\f148';
}
@mixin icon-mixin-duplicate {
    content: '\f149';
}
.icon-duplicate:before {
    content: '\f149';
}
@mixin icon-mixin-edit {
    content: '\f14a';
}
.icon-edit:before {
    content: '\f14a';
}
@mixin icon-mixin-enterprise {
    content: '\f14b';
}
.icon-enterprise:before {
    content: '\f14b';
}
@mixin icon-mixin-enumerations {
    content: '\f14c';
}
.icon-enumerations:before {
    content: '\f14c';
}
@mixin icon-mixin-error {
    content: '\f14d';
}
.icon-error:before {
    content: '\f14d';
}
@mixin icon-mixin-export-atom {
    content: '\f14e';
}
.icon-export-atom:before {
    content: '\f14e';
}
@mixin icon-mixin-export-bcf {
    content: '\f14f';
}
.icon-export-bcf:before {
    content: '\f14f';
}
@mixin icon-mixin-export-csv {
    content: '\f150';
}
.icon-export-csv:before {
    content: '\f150';
}
@mixin icon-mixin-export-pdf-descr {
    content: '\f151';
}
.icon-export-pdf-descr:before {
    content: '\f151';
}
@mixin icon-mixin-export-pdf-with-descriptions {
    content: '\f152';
}
.icon-export-pdf-with-descriptions:before {
    content: '\f152';
}
@mixin icon-mixin-export-pdf {
    content: '\f153';
}
.icon-export-pdf:before {
    content: '\f153';
}
@mixin icon-mixin-export-xls-descr {
    content: '\f154';
}
.icon-export-xls-descr:before {
    content: '\f154';
}
@mixin icon-mixin-export-xls-with-descriptions {
    content: '\f155';
}
.icon-export-xls-with-descriptions:before {
    content: '\f155';
}
@mixin icon-mixin-export-xls-with-relations {
    content: '\f156';
}
.icon-export-xls-with-relations:before {
    content: '\f156';
}
@mixin icon-mixin-export-xls {
    content: '\f157';
}
.icon-export-xls:before {
    content: '\f157';
}
@mixin icon-mixin-export {
    content: '\f158';
}
.icon-export:before {
    content: '\f158';
}
@mixin icon-mixin-external-link {
    content: '\f159';
}
.icon-external-link:before {
    content: '\f159';
}
@mixin icon-mixin-faq {
    content: '\f15a';
}
.icon-faq:before {
    content: '\f15a';
}
@mixin icon-mixin-filter {
    content: '\f15b';
}
.icon-filter:before {
    content: '\f15b';
}
@mixin icon-mixin-flag {
    content: '\f15c';
}
.icon-flag:before {
    content: '\f15c';
}
@mixin icon-mixin-folder-add {
    content: '\f15d';
}
.icon-folder-add:before {
    content: '\f15d';
}
@mixin icon-mixin-folder-locked {
    content: '\f15e';
}
.icon-folder-locked:before {
    content: '\f15e';
}
@mixin icon-mixin-folder-open {
    content: '\f15f';
}
.icon-folder-open:before {
    content: '\f15f';
}
@mixin icon-mixin-folder-remove {
    content: '\f160';
}
.icon-folder-remove:before {
    content: '\f160';
}
@mixin icon-mixin-folder {
    content: '\f161';
}
.icon-folder:before {
    content: '\f161';
}
@mixin icon-mixin-forums {
    content: '\f162';
}
.icon-forums:before {
    content: '\f162';
}
@mixin icon-mixin-from-fullscreen {
    content: '\f163';
}
.icon-from-fullscreen:before {
    content: '\f163';
}
@mixin icon-mixin-getting-started {
    content: '\f164';
}
.icon-getting-started:before {
    content: '\f164';
}
@mixin icon-mixin-glossar {
    content: '\f165';
}
.icon-glossar:before {
    content: '\f165';
}
@mixin icon-mixin-google-plus {
    content: '\f166';
}
.icon-google-plus:before {
    content: '\f166';
}
@mixin icon-mixin-group-by {
    content: '\f167';
}
.icon-group-by:before {
    content: '\f167';
}
@mixin icon-mixin-group {
    content: '\f168';
}
.icon-group:before {
    content: '\f168';
}
@mixin icon-mixin-hamburger {
    content: '\f169';
}
.icon-hamburger:before {
    content: '\f169';
}
@mixin icon-mixin-headline1 {
    content: '\f16a';
}
.icon-headline1:before {
    content: '\f16a';
}
@mixin icon-mixin-headline2 {
    content: '\f16b';
}
.icon-headline2:before {
    content: '\f16b';
}
@mixin icon-mixin-headline3 {
    content: '\f16c';
}
.icon-headline3:before {
    content: '\f16c';
}
@mixin icon-mixin-headset {
    content: '\f16d';
}
.icon-headset:before {
    content: '\f16d';
}
@mixin icon-mixin-help {
    content: '\f16e';
}
.icon-help:before {
    content: '\f16e';
}
@mixin icon-mixin-help1 {
    content: '\f16f';
}
.icon-help1:before {
    content: '\f16f';
}
@mixin icon-mixin-help2 {
    content: '\f170';
}
.icon-help2:before {
    content: '\f170';
}
@mixin icon-mixin-hierarchy {
    content: '\f171';
}
.icon-hierarchy:before {
    content: '\f171';
}
@mixin icon-mixin-home {
    content: '\f172';
}
.icon-home:before {
    content: '\f172';
}
@mixin icon-mixin-hosting {
    content: '\f173';
}
.icon-hosting:before {
    content: '\f173';
}
@mixin icon-mixin-ifc {
    content: '\f174';
}
.icon-ifc:before {
    content: '\f174';
}
@mixin icon-mixin-image1 {
    content: '\f175';
}
.icon-image1:before {
    content: '\f175';
}
@mixin icon-mixin-image2 {
    content: '\f176';
}
.icon-image2:before {
    content: '\f176';
}
@mixin icon-mixin-import {
    content: '\f177';
}
.icon-import:before {
    content: '\f177';
}
@mixin icon-mixin-info1 {
    content: '\f178';
}
.icon-info1:before {
    content: '\f178';
}
@mixin icon-mixin-info2 {
    content: '\f179';
}
.icon-info2:before {
    content: '\f179';
}
@mixin icon-mixin-input-disabled {
    content: '\f17a';
}
.icon-input-disabled:before {
    content: '\f17a';
}
@mixin icon-mixin-installation-services {
    content: '\f17b';
}
.icon-installation-services:before {
    content: '\f17b';
}
@mixin icon-mixin-italic {
    content: '\f17c';
}
.icon-italic:before {
    content: '\f17c';
}
@mixin icon-mixin-key {
    content: '\f17d';
}
.icon-key:before {
    content: '\f17d';
}
@mixin icon-mixin-link {
    content: '\f17e';
}
.icon-link:before {
    content: '\f17e';
}
@mixin icon-mixin-loading1 {
    content: '\f17f';
}
.icon-loading1:before {
    content: '\f17f';
}
@mixin icon-mixin-loading2 {
    content: '\f180';
}
.icon-loading2:before {
    content: '\f180';
}
@mixin icon-mixin-location {
    content: '\f181';
}
.icon-location:before {
    content: '\f181';
}
@mixin icon-mixin-locked {
    content: '\f182';
}
.icon-locked:before {
    content: '\f182';
}
@mixin icon-mixin-logout {
    content: '\f183';
}
.icon-logout:before {
    content: '\f183';
}
@mixin icon-mixin-mail1 {
    content: '\f184';
}
.icon-mail1:before {
    content: '\f184';
}
@mixin icon-mixin-mail2 {
    content: '\f185';
}
.icon-mail2:before {
    content: '\f185';
}
@mixin icon-mixin-maintenance-support {
    content: '\f186';
}
.icon-maintenance-support:before {
    content: '\f186';
}
@mixin icon-mixin-meetings {
    content: '\f187';
}
.icon-meetings:before {
    content: '\f187';
}
@mixin icon-mixin-menu {
    content: '\f188';
}
.icon-menu:before {
    content: '\f188';
}
@mixin icon-mixin-merge-branch {
    content: '\f189';
}
.icon-merge-branch:before {
    content: '\f189';
}
@mixin icon-mixin-microphone {
    content: '\f18a';
}
.icon-microphone:before {
    content: '\f18a';
}
@mixin icon-mixin-milestone {
    content: '\f18b';
}
.icon-milestone:before {
    content: '\f18b';
}
@mixin icon-mixin-minus1 {
    content: '\f18c';
}
.icon-minus1:before {
    content: '\f18c';
}
@mixin icon-mixin-minus2 {
    content: '\f18d';
}
.icon-minus2:before {
    content: '\f18d';
}
@mixin icon-mixin-mobile {
    content: '\f18e';
}
.icon-mobile:before {
    content: '\f18e';
}
@mixin icon-mixin-modules {
    content: '\f18f';
}
.icon-modules:before {
    content: '\f18f';
}
@mixin icon-mixin-more {
    content: '\f190';
}
.icon-more:before {
    content: '\f190';
}
@mixin icon-mixin-move {
    content: '\f191';
}
.icon-move:before {
    content: '\f191';
}
@mixin icon-mixin-movie {
    content: '\f192';
}
.icon-movie:before {
    content: '\f192';
}
@mixin icon-mixin-music {
    content: '\f193';
}
.icon-music:before {
    content: '\f193';
}
@mixin icon-mixin-new-planning-element {
    content: '\f194';
}
.icon-new-planning-element:before {
    content: '\f194';
}
@mixin icon-mixin-news {
    content: '\f195';
}
.icon-news:before {
    content: '\f195';
}
@mixin icon-mixin-no-hierarchy {
    content: '\f196';
}
.icon-no-hierarchy:before {
    content: '\f196';
}
@mixin icon-mixin-no-zen-mode {
    content: '\f197';
}
.icon-no-zen-mode:before {
    content: '\f197';
}
@mixin icon-mixin-not-supported {
    content: '\f198';
}
.icon-not-supported:before {
    content: '\f198';
}
@mixin icon-mixin-notes {
    content: '\f199';
}
.icon-notes:before {
    content: '\f199';
}
@mixin icon-mixin-openid {
    content: '\f19a';
}
.icon-openid:before {
    content: '\f19a';
}
@mixin icon-mixin-openproject {
    content: '\f19b';
}
.icon-openproject:before {
    content: '\f19b';
}
@mixin icon-mixin-ordered-list {
    content: '\f19c';
}
.icon-ordered-list:before {
    content: '\f19c';
}
@mixin icon-mixin-outline {
    content: '\f19d';
}
.icon-outline:before {
    content: '\f19d';
}
@mixin icon-mixin-paragraph-left {
    content: '\f19e';
}
.icon-paragraph-left:before {
    content: '\f19e';
}
@mixin icon-mixin-paragraph-right {
    content: '\f19f';
}
.icon-paragraph-right:before {
    content: '\f19f';
}
@mixin icon-mixin-paragraph {
    content: '\f1a0';
}
.icon-paragraph:before {
    content: '\f1a0';
}
@mixin icon-mixin-payment-history {
    content: '\f1a1';
}
.icon-payment-history:before {
    content: '\f1a1';
}
@mixin icon-mixin-phone {
    content: '\f1a2';
}
.icon-phone:before {
    content: '\f1a2';
}
@mixin icon-mixin-pin {
    content: '\f1a3';
}
.icon-pin:before {
    content: '\f1a3';
}
@mixin icon-mixin-play {
    content: '\f1a4';
}
.icon-play:before {
    content: '\f1a4';
}
@mixin icon-mixin-plugins {
    content: '\f1a5';
}
.icon-plugins:before {
    content: '\f1a5';
}
@mixin icon-mixin-plus {
    content: '\f1a6';
}
.icon-plus:before {
    content: '\f1a6';
}
@mixin icon-mixin-pre {
    content: '\f1a7';
}
.icon-pre:before {
    content: '\f1a7';
}
@mixin icon-mixin-presentation {
    content: '\f1a8';
}
.icon-presentation:before {
    content: '\f1a8';
}
@mixin icon-mixin-preview {
    content: '\f1a9';
}
.icon-preview:before {
    content: '\f1a9';
}
@mixin icon-mixin-print {
    content: '\f1aa';
}
.icon-print:before {
    content: '\f1aa';
}
@mixin icon-mixin-priority {
    content: '\f1ab';
}
.icon-priority:before {
    content: '\f1ab';
}
@mixin icon-mixin-project-types {
    content: '\f1ac';
}
.icon-project-types:before {
    content: '\f1ac';
}
@mixin icon-mixin-projects {
    content: '\f1ad';
}
.icon-projects:before {
    content: '\f1ad';
}
@mixin icon-mixin-publish {
    content: '\f1ae';
}
.icon-publish:before {
    content: '\f1ae';
}
@mixin icon-mixin-pulldown-up {
    content: '\f1af';
}
.icon-pulldown-up:before {
    content: '\f1af';
}
@mixin icon-mixin-pulldown {
    content: '\f1b0';
}
.icon-pulldown:before {
    content: '\f1b0';
}
@mixin icon-mixin-quote {
    content: '\f1b1';
}
.icon-quote:before {
    content: '\f1b1';
}
@mixin icon-mixin-quote2 {
    content: '\f1b2';
}
.icon-quote2:before {
    content: '\f1b2';
}
@mixin icon-mixin-redo {
    content: '\f1b3';
}
.icon-redo:before {
    content: '\f1b3';
}
@mixin icon-mixin-relation-follows {
    content: '\f1b4';
}
.icon-relation-follows:before {
    content: '\f1b4';
}
@mixin icon-mixin-relation-new-child {
    content: '\f1b5';
}
.icon-relation-new-child:before {
    content: '\f1b5';
}
@mixin icon-mixin-relation-precedes {
    content: '\f1b6';
}
.icon-relation-precedes:before {
    content: '\f1b6';
}
@mixin icon-mixin-relations {
    content: '\f1b7';
}
.icon-relations:before {
    content: '\f1b7';
}
@mixin icon-mixin-reload {
    content: '\f1b8';
}
.icon-reload:before {
    content: '\f1b8';
}
@mixin icon-mixin-reminder {
    content: '\f1b9';
}
.icon-reminder:before {
    content: '\f1b9';
}
@mixin icon-mixin-remove {
    content: '\f1ba';
}
.icon-remove:before {
    content: '\f1ba';
}
@mixin icon-mixin-rename {
    content: '\f1bb';
}
.icon-rename:before {
    content: '\f1bb';
}
@mixin icon-mixin-reported-by-me {
    content: '\f1bc';
}
.icon-reported-by-me:before {
    content: '\f1bc';
}
@mixin icon-mixin-resizer-bottom-right {
    content: '\f1bd';
}
.icon-resizer-bottom-right:before {
    content: '\f1bd';
}
@mixin icon-mixin-resizer-vertical-lines {
    content: '\f1be';
}
.icon-resizer-vertical-lines:before {
    content: '\f1be';
}
@mixin icon-mixin-roadmap {
    content: '\f1bf';
}
.icon-roadmap:before {
    content: '\f1bf';
}
@mixin icon-mixin-rss {
    content: '\f1c0';
}
.icon-rss:before {
    content: '\f1c0';
}
@mixin icon-mixin-rubber {
    content: '\f1c1';
}
.icon-rubber:before {
    content: '\f1c1';
}
@mixin icon-mixin-save {
    content: '\f1c2';
}
.icon-save:before {
    content: '\f1c2';
}
@mixin icon-mixin-search {
    content: '\f1c3';
}
.icon-search:before {
    content: '\f1c3';
}
@mixin icon-mixin-send-mail {
    content: '\f1c4';
}
.icon-send-mail:before {
    content: '\f1c4';
}
@mixin icon-mixin-server-key {
    content: '\f1c5';
}
.icon-server-key:before {
    content: '\f1c5';
}
@mixin icon-mixin-settings {
    content: '\f1c6';
}
.icon-settings:before {
    content: '\f1c6';
}
@mixin icon-mixin-settings2 {
    content: '\f1c7';
}
.icon-settings2:before {
    content: '\f1c7';
}
@mixin icon-mixin-settings3 {
    content: '\f1c8';
}
.icon-settings3:before {
    content: '\f1c8';
}
@mixin icon-mixin-settings4 {
    content: '\f1c9';
}
.icon-settings4:before {
    content: '\f1c9';
}
@mixin icon-mixin-shortcuts {
    content: '\f1ca';
}
.icon-shortcuts:before {
    content: '\f1ca';
}
@mixin icon-mixin-show-all-projects {
    content: '\f1cb';
}
.icon-show-all-projects:before {
    content: '\f1cb';
}
@mixin icon-mixin-show-more-horizontal {
    content: '\f1cc';
}
.icon-show-more-horizontal:before {
    content: '\f1cc';
}
@mixin icon-mixin-show-more {
    content: '\f1cd';
}
.icon-show-more:before {
    content: '\f1cd';
}
@mixin icon-mixin-slack {
    content: '\f1ce';
}
.icon-slack:before {
    content: '\f1ce';
}
@mixin icon-mixin-sort-ascending {
    content: '\f1cf';
}
.icon-sort-ascending:before {
    content: '\f1cf';
}
@mixin icon-mixin-sort-by {
    content: '\f1d0';
}
.icon-sort-by:before {
    content: '\f1d0';
}
@mixin icon-mixin-sort-descending {
    content: '\f1d1';
}
.icon-sort-descending:before {
    content: '\f1d1';
}
@mixin icon-mixin-sort-down {
    content: '\f1d2';
}
.icon-sort-down:before {
    content: '\f1d2';
}
@mixin icon-mixin-sort-up {
    content: '\f1d3';
}
.icon-sort-up:before {
    content: '\f1d3';
}
@mixin icon-mixin-square {
    content: '\f1d4';
}
.icon-square:before {
    content: '\f1d4';
}
@mixin icon-mixin-star {
    content: '\f1d5';
}
.icon-star:before {
    content: '\f1d5';
}
@mixin icon-mixin-status-reporting {
    content: '\f1d6';
}
.icon-status-reporting:before {
    content: '\f1d6';
}
@mixin icon-mixin-status {
    content: '\f1d7';
}
.icon-status:before {
    content: '\f1d7';
}
@mixin icon-mixin-strike-through {
    content: '\f1d8';
}
.icon-strike-through:before {
    content: '\f1d8';
}
@mixin icon-mixin-text {
    content: '\f1d9';
}
.icon-text:before {
    content: '\f1d9';
}
@mixin icon-mixin-ticket-checked {
    content: '\f1da';
}
.icon-ticket-checked:before {
    content: '\f1da';
}
@mixin icon-mixin-ticket-down {
    content: '\f1db';
}
.icon-ticket-down:before {
    content: '\f1db';
}
@mixin icon-mixin-ticket-edit {
    content: '\f1dc';
}
.icon-ticket-edit:before {
    content: '\f1dc';
}
@mixin icon-mixin-ticket-minus {
    content: '\f1dd';
}
.icon-ticket-minus:before {
    content: '\f1dd';
}
@mixin icon-mixin-ticket-note {
    content: '\f1de';
}
.icon-ticket-note:before {
    content: '\f1de';
}
@mixin icon-mixin-ticket {
    content: '\f1df';
}
.icon-ticket:before {
    content: '\f1df';
}
@mixin icon-mixin-time {
    content: '\f1e0';
}
.icon-time:before {
    content: '\f1e0';
}
@mixin icon-mixin-to-fullscreen {
    content: '\f1e1';
}
.icon-to-fullscreen:before {
    content: '\f1e1';
}
@mixin icon-mixin-training-consulting {
    content: '\f1e2';
}
.icon-training-consulting:before {
    content: '\f1e2';
}
@mixin icon-mixin-two-factor-authentication {
    content: '\f1e3';
}
.icon-two-factor-authentication:before {
    content: '\f1e3';
}
@mixin icon-mixin-types {
    content: '\f1e4';
}
.icon-types:before {
    content: '\f1e4';
}
@mixin icon-mixin-underline {
    content: '\f1e5';
}
.icon-underline:before {
    content: '\f1e5';
}
@mixin icon-mixin-undo {
    content: '\f1e6';
}
.icon-undo:before {
    content: '\f1e6';
}
@mixin icon-mixin-unit {
    content: '\f1e7';
}
.icon-unit:before {
    content: '\f1e7';
}
@mixin icon-mixin-unlocked {
    content: '\f1e8';
}
.icon-unlocked:before {
    content: '\f1e8';
}
@mixin icon-mixin-unordered-list {
    content: '\f1e9';
}
.icon-unordered-list:before {
    content: '\f1e9';
}
@mixin icon-mixin-unwatched {
    content: '\f1ea';
}
.icon-unwatched:before {
    content: '\f1ea';
}
@mixin icon-mixin-upload {
    content: '\f1eb';
}
.icon-upload:before {
    content: '\f1eb';
}
@mixin icon-mixin-user-minus {
    content: '\f1ec';
}
.icon-user-minus:before {
    content: '\f1ec';
}
@mixin icon-mixin-user-plus {
    content: '\f1ed';
}
.icon-user-plus:before {
    content: '\f1ed';
}
@mixin icon-mixin-user {
    content: '\f1ee';
}
.icon-user:before {
    content: '\f1ee';
}
@mixin icon-mixin-view-card {
    content: '\f1ef';
}
.icon-view-card:before {
    content: '\f1ef';
}
@mixin icon-mixin-view-fullscreen {
    content: '\f1f0';
}
.icon-view-fullscreen:before {
    content: '\f1f0';
}
@mixin icon-mixin-view-list {
    content: '\f1f1';
}
.icon-view-list:before {
    content: '\f1f1';
}
@mixin icon-mixin-view-model {
    content: '\f1f2';
}
.icon-view-model:before {
    content: '\f1f2';
}
@mixin icon-mixin-view-split-viewer-table {
    content: '\f1f3';
}
.icon-view-split-viewer-table:before {
    content: '\f1f3';
}
@mixin icon-mixin-view-split {
    content: '\f1f4';
}
.icon-view-split:before {
    content: '\f1f4';
}
@mixin icon-mixin-view-split2 {
    content: '\f1f5';
}
.icon-view-split2:before {
    content: '\f1f5';
}
@mixin icon-mixin-view-timeline {
    content: '\f1f6';
}
.icon-view-timeline:before {
    content: '\f1f6';
}
@mixin icon-mixin-warning {
    content: '\f1f7';
}
.icon-warning:before {
    content: '\f1f7';
}
@mixin icon-mixin-watched {
    content: '\f1f8';
}
.icon-watched:before {
    content: '\f1f8';
}
@mixin icon-mixin-wiki-edit {
    content: '\f1f9';
}
.icon-wiki-edit:before {
    content: '\f1f9';
}
@mixin icon-mixin-wiki {
    content: '\f1fa';
}
.icon-wiki:before {
    content: '\f1fa';
}
@mixin icon-mixin-wiki2 {
    content: '\f1fb';
}
.icon-wiki2:before {
    content: '\f1fb';
}
@mixin icon-mixin-work-packages {
    content: '\f1fc';
}
.icon-work-packages:before {
    content: '\f1fc';
}
@mixin icon-mixin-workflow {
    content: '\f1fd';
}
.icon-workflow:before {
    content: '\f1fd';
}
@mixin icon-mixin-yes {
    content: '\f1fe';
}
.icon-yes:before {
    content: '\f1fe';
}
@mixin icon-mixin-zen-mode {
    content: '\f1ff';
}
.icon-zen-mode:before {
    content: '\f1ff';
}
@mixin icon-mixin-zoom-auto {
    content: '\f200';
}
.icon-zoom-auto:before {
    content: '\f200';
}
@mixin icon-mixin-zoom-in {
    content: '\f201';
}
.icon-zoom-in:before {
    content: '\f201';
}
@mixin icon-mixin-zoom-out {
    content: '\f202';
}
.icon-zoom-out:before {
    content: '\f202';
}
