.op-link
  display: inline
  color: var(--content-link-color)
  text-decoration: none
  padding: 0
  margin: 0
  background: 0
  border: 0
  font: inherit
  cursor: pointer

  &:hover,
  &:active
    color: var(--content-link-hover-active-color)
    text-decoration: underline

  &:focus
    color: var(--content-link-hover-active-color)
  
